<template>
	<div class="signin-detail">
		<w-navTab titleText="打卡详情"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="nav-top">
			<div id="map"></div>
			<div class="map-container">
				<div class="map-activity" @click.stop="onClickActivity">
					<div class="map-text">活动位置</div>
					<div class="map-icon">
						<img src="@/assets/img/icon501.png" alt="">
					</div>
				</div>
				<div class="map-user" @click.stop="onClickUser">
					<div class="map-text">刷新定位</div>
					<div class="map-icon">
						<img src="@/assets/img/icon502.png" alt="">
					</div>
				</div>
			</div>
		</div>
		<div class="head">
			<div class="middle">
				<div class="middle-item">
					<div class="middle-title">开始打卡：</div>
					<div class="middle-text">{{ activity.begin_date }}</div>
					<div class="middle-text">{{ activity.begin_time }}</div>
				</div>
				<div class="middle-item">
					<div class="middle-title">结束打卡：</div>
					<div class="middle-text">{{ activity.finish_date }}</div>
					<div class="middle-text">{{ activity.finish_time }}</div>
				</div>
			</div>
			<div class="middle">
				<div class="middle-item">
					<div class="middle-title">打卡地点：</div>
					<div class="middle-text">{{ activity.address }}</div>
				</div>
			</div>
			<div class="middle">
				<div class="middle-item">
					<div class="middle-title">在岗时间：</div>
					<div class="middle-text color">{{ serviceTime }}</div>
				</div>
			</div>
			<div class="middle-bottom" @click="callPhone()">
				<div class="middle-bottom-picture"><img src="@/assets/img/activity_detail_telephone.png" alt="" /></div>
				<div class="middle-bottom-title">活动负责人电话</div>
			</div>
			<!-- 打卡按钮状态 -->
			<div class="sign-btn sign-btn-warning" v-if="activity.situation == 1">
				<div class="sign-btn-title">未开始</div>
				<div class="sign-btn-text">活动未开始</div>
			</div>
			<div class="sign-btn sign-btn-disabled" v-if="activity.situation == 3">
				<div class="sign-btn-title">已结束</div>
				<div class="sign-btn-text">活动已结束</div>
			</div>
			<div class="sign-btn sign-btn-disabled" v-if="activity.situation == 2">
				<div class="sign-btn-title">已结束</div>
				<div class="sign-btn-text">活动已结束</div>
			</div>
			<div class="sign-btn sign-btn-grad" v-if="activity.situation == 4" @click="onClickSign('beginRegister')">
				<div class="sign-btn-title">开始</div>
				<div class="sign-btn-text">{{ this.isWithIn == true ? '范围正常' : '范围异常' }}</div>
			</div>
			<div class="sign-btn sign-btn-danger" v-if="activity.situation == 5" @click="onClickSign('finishRegister')">
				<div class="sign-btn-title">结束</div>
				<div class="sign-btn-text">{{ this.isWithIn == true ? '范围正常' : '范围异常' }}</div>
			</div>
			<div class="sign-btn sign-btn-disabled" v-if="activity.situation == 6">
				<div class="sign-btn-title">已完成</div>
				<div class="sign-btn-text">活动已完成</div>
			</div>
			<div class="sign-btn sign-btn-disabled" v-if="activity.situation == 7">
				<div class="sign-btn-title">未结束</div>
				<div class="sign-btn-text">未打结束卡</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SignInApi from '@/api/signIn';
	import VantVendor from '@/vendor/vant';
	import WechatVendor from '@/vendor/wechat';
	import MomentVendor from '@/vendor/moment';
	import LoadingPage from '@/components/LoadingPage';

	export default {
		name: 'SignInDetail',
		data() {
			return {
				map: null,
				circle: null,
				markerLayer: null,
				recordId: '',
				activity: '',
				register: '',
				latitude: 0,
				longitude: 0,
				serviceTime: '',
				currentTime: 0,
				isWithIn: false,
				loadingPage: true,
				serviceTimeInterval: null
			};
		},
		created() {
			this.recordId = this.$route.params.id;
			this.getRegisterDetail();
		},
		beforeRouteLeave(to, from, next) {
			this.stopServiceTime();
			this.stopGetLocation();
			next();
		},
		methods: {
			onClickActivity() {
				this.map.panTo(new TMap.LatLng(this.activity.latitude, this.activity.longitude))
			},
			onClickUser() {
				this.getLocation();
				VantVendor.Toast.success('刷新定位成功');
			},
			getRegisterDetail() {
				SignInApi.getRegisterDetail({
						record_id: this.recordId
					})
					.then(result => {
						this.activity = result.data.activity || {};
						this.register = result.data.register || {};
						this.currentTime = result.data.timestamp;
						this.createMap(this.activity.latitude, this.activity.longitude);
						this.startServiceTime();
						this.startGetLocation();
						this.loadingPage = false;
					})
					.catch(error => {
						console.log(error);
					});
			},
			onClickSign(methods) {
				VantVendor.Toast.loading({
				  message: '加载中...',
				  forbidClick: true
				});
				this.getLocation(()=>{
					VantVendor.Toast.clear()
					
					if (this.isWithIn == false) {
						return VantVendor.Toast('请在活动范围内打卡');
					}
					
					if (methods == 'beginRegister') {
						this.beginRegister();
					}
					
					if (methods == 'finishRegister') {
						this.finishRegister();
					}
				})
			},
			beginRegister() {
				VantVendor.Dialog.confirm({
						title: '您确认开始打卡吗？',
						message: '开始打卡系统记录服务时长'
					})
					.then(() => {
						VantVendor.Toast.loading({
						  message: '加载中...',
						  forbidClick: true
						});
						SignInApi.beginRegister({
								record_id: this.recordId,
								latitude: this.latitude,
								longitude: this.longitude
							})
							.then(result => {
								VantVendor.Toast.success('开始打卡成功');
								this.activity.situation = 5;
								this.register.begin_time = result.data.timestamp;
								this.currentTime = result.data.timestamp;
								this.startServiceTime();
							})
							.catch(error => {
								VantVendor.Toast(error.msg);
							});
					})
					.catch(() => {
						console.log('用户取消');
					});
			},
			// 结束打卡
			finishRegister() {
				VantVendor.Dialog.confirm({
						title: '您确认结束打卡吗？',
						message: '结束打卡后系统发放爱心值'
					})
					.then(() => {
						VantVendor.Toast.loading({
						  message: '加载中...',
						  forbidClick: true
						});
						this.getLocation(()=>{
							VantVendor.Toast.clear()
							
							if (this.isWithIn == false) {
								return VantVendor.Toast('请在活动范围内打卡');
							}
							
							this.onFinishRegister()
						})
					})
					.catch(() => {
						console.log('用户取消');
					});
			},
			onFinishRegister(){
				SignInApi.finishRegister({
						record_id: this.recordId,
						latitude: this.latitude,
						longitude: this.longitude
					})
					.then(result => {
						VantVendor.Toast.success('结束打卡成功');
						this.stopServiceTime();
						this.stopGetLocation();
						this.activity.situation = 6;
					})
					.catch(error => {
						VantVendor.Toast(error.msg);
					});
			},
			startServiceTime() {
				this.refreshServiceTime();
				if (this.activity.situation == 5) {
					// 只在打卡中显示服务时长
					this.serviceTimeInterval = setInterval(() => this.refreshServiceTime(), 1000);
				}
			},
			startGetLocation() {
				if (this.activity.situation < 6) {
					// 只在未结束打卡前自动定位
					this.getLocation();
					setTimeout(() => this.getLocation(), 5000);
				}
			},
			refreshServiceTime() {
				let serviceTime = 0;

				this.currentTime++;

				if (this.activity.situation == 5) {
					serviceTime = this.currentTime - this.register.begin_time;
				}

				if (this.activity.situation == 6) {
					serviceTime = this.register.finish_time - this.register.begin_time;
				}

				this.serviceTime = MomentVendor.utc(serviceTime * 1000).format('HH时mm分ss秒');
			},
			createMap(latitude, longitude) {
				if (this.map !== null) {
					return false;
				}

				let center = new TMap.LatLng(latitude, longitude);

				// 初始化地图
				this.map = new TMap.Map('map', {
					rotation: 0,
					pitch: 0,
					zoom: 15,
					center: center,
					showControl: false
				});

				// 添加地图控件
				let control = this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
				let rotation = this.map.getControl(TMap.constants.DEFAULT_CONTROL_ID.ROTATION);

				this.map.addControl(control);
				this.map.addControl(rotation);

				// 绘制打卡范围
				this.circle = new TMap.MultiCircle({
					map: this.map,
					geometries: [{
						id: 'circle',
						center: center,
						radius: this.activity.distance
					}]
				});
				
				// 绘制标记点
				this.markerLayer = new TMap.MultiMarker({
					map: this.map,
					styles: {
						activityMarkerStyle: new TMap.MarkerStyle({
							width: 20,
							height: 30,
							anchor: {
								x: 10,
								y: 30
							}
						}),
						UserMarkerStyle: new TMap.MarkerStyle({
							width: 30,
							height: 30,
							anchor: {
								x: 15,
								y: 15
							},
							src: require('@/assets/img/position.png')
						})
					},
					geometries: [{
						id: 'activityMarker',
						styleId: 'activityMarkerStyle',
						position: center
					}]
				});
			},
			getLocation(fn) {
				WechatVendor.getLocation().then(result => {
						this.latitude = result.latitude
						this.longitude = result.longitude
						
						let position = new TMap.LatLng(this.latitude, this.longitude)
						
						// 更新标记点
						this.markerLayer.updateGeometries({
							id: 'userMarker',
							styleId: 'UserMarkerStyle',
							position: position
						})
						
						// 检测是否在打卡范围内
						this.isWithIn = TMap.geometry.isPointInPolygon(position, this.circle.geometries[0].paths)
						typeof fn === 'function' && fn()
					})
					.catch(error => {
						VantVendor.Toast(error.msg)
					});
			},
			callPhone() {
				window.location.href = 'tel://' + this.activity.contact_telephone;
			},
			stopServiceTime() {
				clearInterval(this.serviceTimeInterval);
			},
			stopGetLocation() {
				clearInterval(this.serviceTimeInterval);
			}
		},
		components: {
			LoadingPage
		}
	};
</script>

<style lang="less" scoped>
	.signin-detail {
		height: 100%;
		background-color: #fff;

		.nav-top {
			width: 100%;
			height: 340px;
			position: relative;

			/deep/#map {
				width: 100%;
				height: 100%;

				.logo-text,
				a {
					display: none;
				}
			}

			.map-container {
				padding: 8px;
				box-sizing: border-box;
				border-radius: 6px;
				background-color: #fff;
				position: absolute;
				right: 10px;
				bottom: 20px;
				z-index: 1000 !important;

				.map-activity {
					display: flex;
					align-items: center;
					margin-bottom: 8px;
				}

				.map-user {
					display: flex;
					align-items: center;
				}

				.map-text {
					color: #222;
					font-size: 15px;
					margin-right: 6px;
				}

				.map-icon {
					width: 24px;
					height: 24px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		.head {
			padding: 10px;
			background-color: #fff;
			box-shadow: 0px 0px 5px rgba(19, 114, 233, 0.05);
			border-radius: 15px 15px 0px 0px;
			margin-top: -10px;
			position: relative;
			z-index: 2;

			.middle {
				background: #f2f2f2;
				border-radius: 6px;
				padding: 0 37px 10px;
				margin-bottom: 10px;

				.middle-item {
					display: flex;
					padding-top: 10px;

					.middle-title {
						width: 90px;
						min-width: 90px;
						font-size: 16px;
						line-height: 26px;
						color: #333;
					}

					.middle-text {
						font-size: 16px;
						line-height: 26px;
						color: #666;
						margin-right: 10px;
					}

					.middle-text:last-child {
						margin-right: 0;
					}

					.color {
						color: #ff6969;
					}
				}
			}

			.middle-bottom {
				display: flex;
				justify-content: center;
				align-items: center;

				.middle-bottom-picture {
					width: 20px;
					min-width: 20px;
					height: 20px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}

				.middle-bottom-title {
					font-size: 18px;
					line-height: 28px;
					color: #6abb1b;
					margin-left: 8px;
				}

				.middle-bottom-refresh {
					width: 24px;
					min-width: 24px;
					height: 24px;
					margin-left: 5px;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}

			.sign-btn {
				width: 128px;
				height: 128px;
				background: #19b500;
				border-radius: 50%;
				text-align: center;
				margin: 0 auto;
				margin-top: 30px;
				margin-bottom: 30px;
				box-shadow: 0 0 0 20px rgba(25, 181, 0, 0.1);
				color: #fff;
				animation-name: signBtnSnimationSuccess;
				animation-duration: 3s;
				animation-iteration-count: infinite;

				&.sign-btn-danger {
					background: #ff535a;
					box-shadow: 0 0 0 20px rgba(240, 171, 68, 0.1);
					animation-name: signBtnSnimationDanger;
				}

				&.sign-btn-warning {
					background: #f0ab44;
					box-shadow: 0 0 0 20px rgba(240, 171, 68, 0.1);
					animation-name: signBtnSnimationYellow;
				}

				&.sign-btn-disabled {
					background: #ccc;
					box-shadow: 0 0 0 0;
					animation-name: none;
				}

				&.sign-btn-grad {
					background: #19b500;
					box-shadow: 0 0 0 20px rgba(25, 181, 0, 0.1);
					animation-name: signBtnSnimationSuccess;
				}

				.sign-btn-title {
					font-weight: bold;
					font-size: 30px;
					line-height: 26px;
					text-align: center;
					padding-top: 40px;
					margin-bottom: 7px;
				}

				.sign-btn-text {
					font-size: 16px;
					line-height: 26px;
					text-align: center;
				}
			}

			@success: rgba(25, 181, 0, 0.1);
			@danger: rgba(255, 83, 90, 0.1);
			@yellow: rgba(240, 171, 68, 0.1);

			@keyframes signBtnSnimationSuccess {
				0% {
					box-shadow: 0 0 0 1px @success;
				}

				25% {
					box-shadow: 0 0 0 20px @success;
				}

				50% {
					box-shadow: 0 0 0 1px @success;
				}

				70% {
					box-shadow: 0 0 0 20px @success;
				}

				100% {
					box-shadow: 0 0 0 1px @success;
				}
			}

			@keyframes signBtnSnimationDanger {
				0% {
					box-shadow: 0 0 0 1px @danger;
				}

				25% {
					box-shadow: 0 0 0 20px @danger;
				}

				50% {
					box-shadow: 0 0 0 1px @danger;
				}

				70% {
					box-shadow: 0 0 0 20px @danger;
				}

				100% {
					box-shadow: 0 0 0 1px @danger;
				}
			}

			@keyframes signBtnSnimationYellow {
				0% {
					box-shadow: 0 0 0 1px @yellow;
				}

				25% {
					box-shadow: 0 0 0 20px @yellow;
				}

				50% {
					box-shadow: 0 0 0 1px @yellow;
				}

				70% {
					box-shadow: 0 0 0 20px @yellow;
				}

				100% {
					box-shadow: 0 0 0 1px @yellow;
				}
			}
		}
	}
</style>